<script>
  export let property = null;
</script>

{#if property && property.logo}
  <img
    class="logo"
    alt="logo"
    src="{property.logo.url.replace(
      'https://assets.property-content.com/files/',
      'https://property-content.imgix.net/'
    )}?auto=compress,format"
  />
{/if}
