<script>
  import { get } from "lodash-es";
  export let property = null;
  export let lang = "en";
</script>

<footer>
  <p>
    {#if "es" === lang}
      Gracias por ayudarnos a hacer de este excelente lugar para vivir.
    {:else if "fr" === lang}
      Merci de nous aider à rendre cet endroit agréable à vivre.
    {:else}
      Thank you for helping us make this a great place to live.
    {/if}
  </p>
  <p>— {get(property, "name") || "Management Office"}</p>
</footer>
